import Switch from "react-switch";
import Price from './Price';

import React, { useState } from "react";


const featuresStart = ['<b>1</b> Companies', '<b>100</b> Assets', '<b>quarterly</b> scan interval','<b>Darknet</b> Monitoring', '<b>Domain</b> Monitoring', '<b>Server</b> Monitoring'];

const featuresBusiness = ['<b>3</b> Companies', '<b>1000</b> Assets', '<b>monthly</b> scan interval','<b>Darknet</b> Monitoring', '<b>Domain</b> Monitoring', '<b>Server</b> Monitoring'];

const featuresPremium = ['<b>10</b> Companies', '<b>5000</b> Assets', '<b>monthly</b> scan interval','<b>Darknet</b> Monitoring', '<b>Domain</b> Monitoring', '<b>Server</b> Monitoring'];

const featuresEnterprise = ['<b>100</b> Companies', '<b>unlimited</b> Assets', '<b>daliy</b> scan interval','<b>Darknet</b> Monitoring', '<b>Domain</b> Monitoring', '<b>Server</b> Monitoring'];



function Home() {

    const [checked, setChecked] = useState(false);
  const handleChange = () => {
      setChecked(!checked)
  }


    return (<><div className='title'>
        <h2>Good security does<br />
        not have to be unaffordable</h2>
      </div>
      <div className='switch'>
        <span>One Year</span>
        <Switch onChange={handleChange} checked={checked} checkedIcon={false} uncheckedIcon={false}  onColor="#181A26" offColor='#BEBDC2' height={20} width={40}/>
        <span>Three Year</span>
        <div className='arrow'>
          <img src="https://risikomonitor.com/payrexx/25-arrow.png.webp" />
        </div>
        <span className='mt-3'>save 25%</span>
      </div>

      <section className='pricing-grid'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3'>
              <Price plan={"Start"} oldprice={'115'} newprice={'75'} oldprice2={'130'} newprice2={'99'} features={featuresStart} checked={checked}  />
            </div>
            <div className='col-lg-3'>
            <Price plan={"Business"} oldprice={'599'} newprice={'399'} oldprice2={'649'} newprice2={'495'} features={featuresBusiness}  checked={checked} />
            </div>
            <div className='col-lg-3'>
            <Price plan={"Premium"} oldprice={'2200'} newprice={'1450'} oldprice2={'2550'} newprice2={'1950'} features={featuresPremium}  checked={checked} />
            </div>
            <div className='col-lg-3'>
            <Price plan={"Enterprise"} oldprice={'7500'} newprice={'5500'} oldprice2={'8500'} newprice2={'6500'} features={featuresEnterprise}  checked={checked} />
            </div>
          </div>
        </div>
      </section></>
      )
}

export default Home;