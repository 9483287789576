import './App.css';
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import Checkout from './Components/Checkout';
import Thankyou from './Components/Thankyou';
import Error from './Components/Error';


function App() {

  


  return (
    <div className="page">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/checkout/:plan/:year" element={<Checkout />} />
          <Route path='/thankyou' element={<Thankyou />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
