import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNames } from "country-list";
import axios from "axios";

function Checkout(){
    const countries = getNames(); // Get list of country names
    
    useEffect(() => {
        axios.get("http://ip-api.com/json")
        .then((response) => {
            if(response.data.country){
                setFormData({ ...formData, country: response.data.country });
            }
            
        })
        .catch((error) => console.error("Error fetching country:", error));
    }, []);

    let type = "Invoice"
    const { plan, year } = useParams();
    const [currentplan, setCurrentplan] = useState(plan);
    const [currnetyear, setCurrentyear] = useState(year);
    const [currnettype, setCurrnettype] = useState(type);

    const [ show, setshow ] = useState(false);

    const [formData, setFormData] = useState({ company_name: "", full_name: "", address: "", zipcode:"", city: "", country: "", phone : "", email:"", checkbox1:"", checkbox2 :"", package: plan,duration : (year == "1-year")?12:36, type : "", iban:"", domainname:"", taxid: "" });
    const [errors, setErrors] = useState({});
    
    const setYear = (e) => {
        setCurrentyear(e);
    }
    const setType = (e) => {
        setCurrnettype(e);
    }
    const setPlan = (e) => {
        setCurrentplan(e);
    }

    const PayNow = async () => {
        //e.preventDefault();
        const newErrors = validate();
        
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            setshow(true);
            try {                
                const response = await fetch("https://risikomonitor.eu/action.php", {
                  method: "POST",
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  body: JSON.stringify(formData),
                });
          
                if (!response.ok) {
                  throw new Error("Failed to submit form");
                }
                setshow(false);
                const result = await response.json();
                window.location.href = result.link;
                
              } catch (error) {
                console.error("Error submitting form:", error);
              }
        }
    }

    const validate = () => {
        let newErrors = {};
        if (!formData.company_name) newErrors.company_name = "Company Name is required";
        if (!formData.full_name) newErrors.full_name = "Full Name is required";
        if (!formData.address) newErrors.address = "Address is required";
        if (!formData.zipcode) newErrors.zipcode = "Zipcode is required";
        if (!formData.city) newErrors.city = "City is required";
        if (!formData.country) newErrors.country = "Country is required";
        if (!formData.phone) newErrors.phone = "Phone is required";
        if (!formData.taxid) newErrors.taxid = "TaxID is required";
        if (!formData.domainname) newErrors.domainname = "Domainname is required";
        if (!formData.email) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Invalid email format";
        }
        if (!formData.checkbox1) newErrors.checkbox1 = "Checkbox is required";
        if (!formData.checkbox2) newErrors.checkbox2 = "Checkbox is required";
        if(currnettype == "Sepa"){
            if (!formData.iban) newErrors.iban = "IBAN is required";
        }
        return newErrors;
    };
    

    return <section className="checkout">
    <div className={show?"bg d-block":"bg"}></div>
    <span className={show?"loader d-block":"loader"}></span>
    <div className="container checkoutbox">            
            <div className="row">
                <div className="col-lg-6 pd30">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Checkout</h2>
                            <h3>Fill the following form to complete your order</h3>
                        </div>
                    </div>
                    <div className="form">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <input type="text" placeholder="Company Name" id="company_name" name="company_name" onChange={(e) => setFormData({ ...formData, company_name: e.target.value })} />
                                    {errors.company_name && <p className="error">{errors.company_name}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <input type="text" placeholder="Full Name" id="full_name" name="full_name" onChange={(e) => setFormData({ ...formData, full_name: e.target.value })} />
                                    {errors.full_name && <p className="error">{errors.full_name}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <input type="text" placeholder="Address" id="address" name="address" onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                    {errors.address && <p className="error">{errors.address}</p>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-field">
                                    <input type="text" placeholder="Zip Code" id="zipcode" name="zipcode" onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })} />
                                    {errors.zipcode && <p className="error">{errors.zipcode}</p>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-field">
                                    <input type="text" placeholder="City" id="city" name="city" onChange={(e) => setFormData({ ...formData, city: e.target.value })} />
                                    {errors.city && <p className="error">{errors.city}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <select value={formData.country} id="country" name="country" onChange={(e) => setFormData({ ...formData, country: e.target.value })}>
                                        <option value="">Select a country</option>
                                        {countries.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                        ))}
                                    </select>
                                    {errors.country && <p className="error">{errors.country}</p>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-field">
                                    <input type="text" placeholder="Phone No" id="phone" name="phone"  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
                                    {errors.phone && <p className="error">{errors.phone}</p>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-field">
                                    <input type="text" placeholder="E-mail" id="email" name="email"  onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <input type="text" placeholder="TaxID" id="taxid" name="taxid"  onChange={(e) => setFormData({ ...formData, taxid: e.target.value })}/>
                                    {errors.taxid && <p className="error">{errors.taxid}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field">
                                    <input type="text" placeholder="Domainname" id="domainname" name="domainname"  onChange={(e) => setFormData({ ...formData, domainname: e.target.value })}/>
                                    {errors.domainname && <p className="error">{errors.domainname}</p>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field2">
                                    <input type="checkbox" id="checkbox1" name="checkbox1" className="register-check" value="checkbox1" onChange={(e) => setFormData({ ...formData, checkbox1: e.target.value })} />
                                    <label htmlFor="checkbox1">Ich bin für dieses unternehmen legitimiert die anmeldung und Scans durchzufuhren</label>
                                </div>
                                {errors.checkbox1 && <p className="error">{errors.checkbox1}</p>}
                            </div>
                            <div className="col-lg-12">
                                <div className="form-field2">
                                    <input type="checkbox" id="checkbox2" name="checkbox2" className="register-check" value="checkbox2" onChange={(e) => setFormData({ ...formData, checkbox2: e.target.value })}/>
                                    <label htmlFor="checkbox2">Ich akzeptiere, dass ausschließlich die AGB der risikomonitor.com gmbh gelten</label>
                                </div>
                                {errors.checkbox2 && <p className="error">{errors.checkbox2}</p>}
                            </div>
                            <input type="hidden" name="duration" id="duration" value={(year == "1-year")?12:36} />
                            <input type="hidden" name="package" id="package" value={plan} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pd30 leftborder">
                    <div className="row" id="checkout-form">
                        <div className="col-lg-12">
                            <h2 className="mb-2">Package</h2>
                        </div>
                        <div className="col-lg-12">
                            <div className="option-form">
                                <div>
                                    <input type="radio" name="package" value="Start" onChange={() => setPlan('Start')} checked={(currentplan == "Start")?"checked":""} id="package_start" />
                                    <label htmlFor="package_start">Start</label>
                                </div>
                                <div>
                                    <input type="radio" name="package" value="Business" onChange={() => setPlan('Business')} checked={(currentplan == "Business")?"checked":""} id="package_business" />
                                    <label htmlFor="package_business">Business</label>
                                </div>
                                <div>
                                    <input type="radio" name="package" value="Premium" onChange={() => setPlan('Premium')} checked={(currentplan == "Premium")?"checked":""} id="package_premium" />
                                    <label htmlFor="package_premium">Premium</label>
                                </div>
                                <div>
                                    <input type="radio" name="package" value="Enterprise" onChange={() => setPlan('Enterprise')} checked={(currentplan == "Enterprise")?"checked":""} id="package_enterprise" />
                                    <label htmlFor="package_enterprise">Enterprise</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 divider">                    
                            <h2>Features included</h2>
                        </div>
                        <div className="col-lg-12">
                            <div className={(currentplan == "Start")?"Start-Info package-info d-block":"Start-Info package-info"}>
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>1</b> scan object (domain name)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>Quarterly</b> automatic scan interval</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>0</b> manual scans pro Monat</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of subdomains</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of TLDs (.at.de.email.cloud)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Website monitoring</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Up/Downtime monitoring</li>
                                </ul>
                            </div>
                            <div className={(currentplan == "Business")?"Business-Info package-info d-block":"Business-Info package-info"}>
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>10 scan</b> object (domain name)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>Monthly</b> automatic scan interval</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>3</b> manual scans pro Monat</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of subdomains</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of TLDs (.at.de.email.cloud)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Website monitoring</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Up/Downtime monitoring</li>
                                </ul>
                            </div>
                            <div className={(currentplan == "Premium")?"Premium-Info package-info d-block":"Premium-Info package-info"}>
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>50 scan</b> object (domain name)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>Daily</b> automatic scan interval</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>10</b> manual scans pro Monat</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of subdomains</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of TLDs (.at.de.email.cloud)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Website monitoring</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Up/Downtime monitoring</li>
                                </ul>
                            </div>
                            <div className={(currentplan == "Enterprise")?"Enterprise-Info package-info d-block":"Enterprise-Info package-info"}>
                                <ul>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>50 scan</b> object (domain name)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>Daily</b> automatic scan interval</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> <b>500</b> manual scans pro Monat</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of subdomains</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Unlimited no of TLDs (.at.de.email.cloud)</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Website monitoring</li>
                                    <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg> Up/Downtime monitoring</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 divider">
                            <h2>Duration Time</h2>
                        </div>
                        <div className="col-lg-12">
                            <div className="option-form2">
                                <div>
                                    <input type="radio" name="duration" value="12" onChange={() => setYear('1-year')} id="12_month" checked={(currnetyear == "1-year")?"checked":""}  />
                                    <label htmlFor="12_month">12 Month</label>
                                </div>
                                <div>
                                    <input type="radio" name="duration" value="36" onChange={() => setYear('3-year')} checked={(currnetyear == "3-year")?"checked":""} id="36_month" />
                                    <label htmlFor="36_month">36 Month</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 price_box divider">
                            <h2>Monthly Price</h2>
                            <h3>€{(currentplan == "Start" && currnetyear == "1-year") ? "99": (currentplan == "Business" && currnetyear == "1-year") ? "495":(currentplan == "Premium" && currnetyear == "1-year") ? "1950":(currentplan == "Enterprise" && currnetyear == "1-year") ? "6500":(currentplan == "Start" && currnetyear == "3-year") ? "75":(currentplan == "Business" && currnetyear == "3-year") ? "399":(currentplan == "Premium" && currnetyear == "3-year") ? "1450":(currentplan == "Enterprise" && currnetyear == "3-year") ? "5500":""}</h3>
                        </div>
                        <div className="col-lg-12 divider d-none">
                            <h2>Payment Method (Recurring) </h2>
                        </div>
                        <div className="col-lg-12 d-none payment_icons">
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/card_bank-transfer.svg" alt="card bank transfer" title="Checkout 1" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/card_paypal.svg" alt="card paypal" title="Checkout 2" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/card_visa.svg" alt="card visa" title="Checkout 3" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/icon_PayPal.jpg" alt="icon PayPal" title="Checkout 4" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/icon_amex.jpg" alt="" title="Checkout 5" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/icon_invoice.jpg" alt="icon invoice" title="Checkout 6" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/icon_mastercard.jpg" alt="icon mastercard" title="Checkout 7" />
                            <img decoding="async" src="https://risikomonitor.com/wp-content/plugins/payrexx-checkout/icons/icon_sepa.jpg" alt="icon sepa" title="Checkout 8" />
                            
                        </div>
                        <div className="col-lg-12 divider">
                            <h2>Payment Method (Recurring)</h2>
                        </div>
                        <div className="col-lg-12">
                            <div className="option-form2">
                                <div>
                                    <input type="radio" name="type" value="Invoice" onChange={() => setType('Invoice')} id="Invoice" checked={(currnettype == "Invoice")?"checked":""}  />
                                    <label htmlFor="Invoice">Invoice</label>
                                </div>
                                <div>
                                    <input type="radio" name="type" value="Sepa" onChange={() => setType('Sepa')} checked={(currnettype == "Sepa")?"checked":""} id="Sepa" />
                                    <label htmlFor="Sepa">Sepa</label>
                                </div>
                            </div>
                        </div>
                        <div className={currnettype == "Invoice"?"col-lg-12 mb-4 divider d-none":"col-lg-12 mb-4 divider"}>
                            <div className="form-field">
                                <input placeholder="IBAN" id="iban" type="text" name="iban" onChange={(e) => setFormData({ ...formData, iban: e.target.value })} />
                                {errors.iban && <p className="error">{errors.iban}</p>}
                            </div>
                        </div>

                        <div className="col-lg-12 pay_now_box">
                            <p>You Save <span className="saving">12%</span> on this package</p>
                            <div className="control-form">
                                <input type="button" id="submit" className="btn btn-submit" onClick={() => PayNow()} value="Pay Now" />
                                <input type="submit" id="submit2" className="d-none" value="Pay Now" />
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
    </div>
</section>
}

export default Checkout