import React from "react";
import { Link } from "react-router-dom";

function Price({plan, oldprice, newprice, oldprice2, newprice2, features, checked}) {

  return (
    <div className={(plan=="Business")?'bg-image black':'bg-image'}>
        <div className="price-box">
            <div className="mp">{(plan=="Business")?"Most popular":""}</div>
            <h3>{plan?plan:"Start"}</h3>
            <h4>€{checked?oldprice:oldprice2}</h4>
            <h3 className="newprice">€{checked?newprice:newprice2}</h3>
            <p>Per Month</p>
        </div>
        <div className="btn">
            <Link to={"checkout/"+ plan + '/' + (checked?"3-year":"1-year")}>Sign Up Now</Link>
        </div>

        <div className="featuredlist">
            <ul>
                {features && features.map((feature) => {
                    return <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg><p dangerouslySetInnerHTML={{ __html: feature }}></p></li>
                })}
            </ul>
        </div>

    </div>
  )
}

export default Price;
